import React from 'react';
import { Form, Input, SubmitButton, Checkbox } from 'formik-antd';
import { Formik } from 'formik';
import { Link } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Alert, Modal } from 'antd';
import { ExternalLink } from '../Links';
import { DOC_LINKS } from '../../constants/generalConstants';
import { isEmailValid } from '../utils';

type TrialFormData = {
	workspaceAddress: string;
	email: string;
	contactName: string;
	agreement: boolean;
};

type Props = {
	onTrialInfoSaved: (
		{
			workspaceAddress,
			email,
			contactName,
		}: Omit<TrialFormData, 'agreement'>,
		finishLoading: () => void,
	) => void;
};

export const TrialForm = ({ onTrialInfoSaved }: Props) => {
	return (
		<Formik
			initialValues={
				{
					workspaceAddress: '',
					email: '',
					contactName: '',
					agreement: false,
				} as TrialFormData
			}
			validate={(values) => {
				const errors: Partial<{ [k in keyof TrialFormData]: string }> =
					{};

				// Validate workspace address
				if (
					!values.workspaceAddress ||
					!values.workspaceAddress.trim()
				) {
					errors.workspaceAddress = 'Required';
				}

				// validate if workspace address is a valid URL
				try {
					new URL(values.workspaceAddress);
				} catch (error) {
					errors.workspaceAddress = 'Invalid URL';
				}

				// Validate email
				if (!values.email || !values.email.trim()) {
					errors.email = 'Required';
				}
				// validate if email is valid
				else if (!isEmailValid(values.email)) {
					errors.email = 'Invalid email address';
				}

				// Validate contact name
				if (!values.contactName || !values.contactName.trim()) {
					errors.contactName = 'Required';
				}

				// Validate agreement
				if (!values.agreement) {
					errors.agreement = 'Please accept the agreement first';
				}

				return errors;
			}}
			render={() => (
				<Form
					name="basic"
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					labelAlign="left"
				>
					<Form.Item label="Email" name="email">
						<Input name="email" placeholder="Email" />
					</Form.Item>
					<Form.Item label="Your Name" name="contactName">
						<Input name="contactName" placeholder="Your Name" />
					</Form.Item>
					<Form.Item
						label="Workspace Site URL"
						name="workspaceAddress"
					>
						<Input
							name="workspaceAddress"
							placeholder="Site URL"
							style={{
								marginBottom: '10px',
							}}
						/>
						<Alert
							message={
								<>
									<Link
										href={DOC_LINKS.SITE_URL_HELP}
										isExternal
									>
										Click here <ExternalLinkIcon mx="2px" />{' '}
									</Link>
									for the guide on where to find this
									Site_URL.
									<br />
									Eg: https://mycompany.rocketchat.com/
									<br />
								</>
							}
							type="info"
							showIcon
						/>
					</Form.Item>

					<Form.Item
						name="agreement"
						style={{
							width: '100%',
						}}
					>
						<Checkbox name="agreement">
							I agree to{' '}
							<ExternalLink
								text="terms of use"
								href={DOC_LINKS.TERMS_OF_USE}
							/>{' '}
							and{' '}
							<ExternalLink
								text="privacy policy"
								href={DOC_LINKS.PRIVACY_POLICY}
							/>{' '}
							of Reminder App of AppsForChat
						</Checkbox>
					</Form.Item>

					<SubmitButton> Start Trial </SubmitButton>
				</Form>
			)}
			onSubmit={(values, { setSubmitting }) => {
				const trimmedWorkspaceAddress = values.workspaceAddress.trim();

				Modal.confirm({
					title: 'Final confirmation',
					content: (
						<p>
							Are you sure this workspace Site_Url is correct?
							<br />
							<u>
								<b>{trimmedWorkspaceAddress}</b>
							</u>
							<br />
							<br />
							You won't be able to change it later.
						</p>
					),
					onOk: () => {
						onTrialInfoSaved(
							{
								workspaceAddress: trimmedWorkspaceAddress,
								email: values.email.trim(),
								contactName: values.contactName.trim(),
							},
							() => setSubmitting(false),
						);
					},
					onCancel: () => {
						setSubmitting(false);
					},
					okText: 'Yes, I am sure. Proceed',
					cancelText: 'No, let me change it',
				});
			}}
		/>
	);
};
