import { useState } from 'react';

type ReturnParams<T> = {
	data: T;
	error?: { message: string; developerCode?: number };
	loading: boolean;
	request: (params?: any) => Promise<void>;
};

export const useApi = <T>(apiFunc: (params?: any) => any): ReturnParams<T> => {
	const [data, setData] = useState(null);
	const [error, setError] = useState({ message: '' });
	const [loading, setLoading] = useState(false);

	const request = async (...args: any) => {
		setLoading(true);
		setError({ message: '' });
		setData(null);
		try {
			const result = await apiFunc(...(args as any));
			console.log('result', result.data);
			setData(result.data);
		} catch (err: any) {
			console.error(
				'Something went wrong!! Response from api',
				err.response,
			);
			setError(
				err?.response?.data?.error || {
					message: `Unexpected error occurred. please contact support`,
				},
			);
		} finally {
			setLoading(false);
		}
	};

	return {
		data: data as T,
		error,
		loading,
		request,
	};
};
