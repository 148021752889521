import { Box, Heading, Container, Text, Stack } from '@chakra-ui/react';
import { useEffect } from 'react';
import InStatusAppDetails from './InStatusAppDetails';
import { NewsletterSubscriptionWithCTA } from './NewsletterSubscriptionCTA';
import ReminderAppDetails from './ReminderAppDetails';
import ChatGPTAppDetails from './ChatGPTAppDetails';
import Testimonial from './Testinomial';

export default function Home() {
	useEffect(() => {
		document.title = 'AppsForChat';
	}, []);
	return (
		<>
			<Container maxW={'6xl'}>
				<Stack
					as={Box}
					textAlign={'center'}
					spacing={{ base: 8, md: 14 }}
					py={{ base: 20 }}
				>
					<Heading
						fontWeight={600}
						fontSize={{ base: '2xl', sm: '4xl', md: '4xl' }}
						lineHeight={'150%'}
					>
						Streamline your Rocketxperience: <br />
						get work done with our apps for
						<br />
						<Text as={'span'} color={'red.400'}>
							Rocket.Chat
						</Text>
					</Heading>
				</Stack>
				<ReminderAppDetails />
				<ChatGPTAppDetails />
				<InStatusAppDetails />
				<NewsletterSubscriptionWithCTA />
				<Testimonial />
			</Container>
		</>
	);
}
