import { Container } from '@chakra-ui/react';
import { Modal } from 'antd';
import { isAxiosError } from 'axios';
import { startTrial } from '../../hooks/api';
import { TrialForm } from '../forms/TrialForm';
import { ExternalLink } from '../Links';

type Props = {
	onClose: () => void;
};

type TrialFormData = {
	workspaceAddress: string;
	email: string;
	contactName: string;
};

export const TrialModal = ({ onClose }: Props) => {
	const handleTrialInfoSaved = async (
		trialData: TrialFormData,
		finishLoading: () => void,
	) => {
		try {
			await startTrial(trialData);

			Modal.success({
				title: 'Trial Started Successfully!!',
				content: (
					<>
						<p>
							We've sent you an email with your trial information.
							Please check your email and follow the instructions
							to start your trial.
							<br />
							<br />
							If you don't see the email in your inbox, please
							check your spam folder. If you still don't see it,
							please{' '}
							<ExternalLink href="/contact" text="contact us" />
						</p>
					</>
				),
				onOk: onClose,
			});
		} catch (error) {
			if (isAxiosError(error)) {
				Modal.error({
					title: 'Error starting trial',
					content: (
						<>
							<p>
								There was an error starting your trial.
								<br />
								<br />
								Details: {error.response?.data?.error?.message}
								<br />
								<br />
								Please{' '}
								<ExternalLink
									href="/contact"
									text="contact us"
								/>{' '}
								for support.
							</p>
						</>
					),
					onOk: finishLoading,
				});
			} else {
				Modal.error({
					title: 'Error starting trial',
					content: (
						<>
							<p>
								There was an unknown error starting your trial.{' '}
								<br />
								Error: {(error as unknown as Error)?.message}
								<br />
								<br />
								Please{' '}
								<ExternalLink
									href="/contact"
									text="contact us"
								/>{' '}
								here with your trial information and we'll start
								your trial for you. Sorry for the inconvenience.
							</p>
						</>
					),
					onOk: finishLoading,
				});
			}
		}
	};

	return (
		<Modal
			open
			onCancel={onClose}
			title="Start Your Free Trial"
			centered
			footer={null}
		>
			<Container py={5}>
				<TrialForm onTrialInfoSaved={handleTrialInfoSaved} />
			</Container>
		</Modal>
	);
};
