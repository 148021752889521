export class EnvVariables {
	static get REACT_APP_PADDLE_PLAN_MONTHLY_ID(): string {
		const id = process.env.REACT_APP_PADDLE_PLAN_MONTHLY_ID;
		if (!id) {
			throw new Error('Paddle monthly plan ID not set');
		}
		return id;
	}

	static get REACT_APP_PADDLE_PLAN_YEARLY_ID(): string {
		const id = process.env.REACT_APP_PADDLE_PLAN_YEARLY_ID;
		if (!id) {
			throw new Error('Paddle yearly plan ID not set');
		}
		return id;
	}

	static get REACT_APP_LMP_API_URL(): string {
		const url = process.env.REACT_APP_LMP_API_URL;
		if (!url) {
			throw new Error('App LMP API URL not set');
		}
		return url;
	}
}
