import { apiClient } from './utils/client';

type ApiResponse<T = null> = SuccessApiResponse<T> | ErrorApiResponse;

interface SuccessApiResponse<T> {
	success: true;
	data: T;
}

interface ErrorApiResponse {
	success: false;
	error: {
		developerCode?: number;
		message: string;
	};
}

export const getLicense = (checkoutId: string) =>
	apiClient.post<ApiResponse<{ license: string }>>(
		'/getLicenseUsingCheckoutId',
		{
			checkoutId,
		},
	);

export const startTrial = (trialData: {
	email: string;
	contactName: string;
	workspaceAddress: string;
}) =>
	apiClient.post<ApiResponse<TrialApiResult>>('/trial', {
		...trialData,
	});

export const sendContactMessage = (trialData: {
	email: string;
	contactName: string;
	message: string;
}) =>
	apiClient.post<ApiResponse>('/contactV2', {
		...trialData,
	});

export const subscribeNewsletter = (data: { email: string }) =>
	apiClient.post<ApiResponse<{ message: string }>>('/newsletter', {
		...data,
	});
