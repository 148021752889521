import { LoadingOutlined } from '@ant-design/icons';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Link } from '@chakra-ui/react';
import { Alert, Button, Modal } from 'antd';
import { useEffect } from 'react';
import { CopyBlock, dracula } from 'react-code-blocks';
import { DOC_LINKS } from '../../constants/generalConstants';
import { getLicense } from '../../hooks/api';
import { useApi } from '../../hooks/utils/useApi';
import { ExternalLink } from '../Links';

type Props = {
	checkoutId: string;
	onClose: () => void;
	plan: 'yearly' | 'monthly';
};

export const PaymentConfirmModal = ({ checkoutId, onClose, plan }: Props) => {
	const licenseData = useApi<{
		success: boolean;
		error?: { message: string };
		data?: { license: string };
	}>(getLicense);

	useEffect(() => {
		// sleep for 8 seconds to allow the payment to go through
		const timer = setTimeout(() => {
			licenseData.request(checkoutId);
		}, 8000);

		return () => clearTimeout(timer);
	}, []);

	const onClickRetry = () => {
		licenseData.request(checkoutId);
	};

	return (
		<Modal
			open
			onCancel={onClose}
			title="Purchase Successful!"
			centered
			footer={null}
		>
			<Alert
				description={
					<p>
						Thank you for your purchase of{' '}
						<u>{plan === 'monthly' ? 'Monthly' : 'Yearly'}</u>{' '}
						Subscription of Premium License.
						<br />
						You should receive an email shortly with your receipt
						and license code.
						<br />
					</p>
				}
				type="success"
				showIcon
				style={{
					marginBottom: '20px',
				}}
			/>

			{(licenseData.loading ||
				(!licenseData.data?.success &&
					!licenseData.error?.message)) && (
				<>
					<LoadingOutlined />
					<p>
						{' '}
						Please hold on while we're generating your license
						code...
					</p>{' '}
				</>
			)}
			{licenseData.data?.success && (
				<>
					<Alert
						message="Congratulations! Your license Key is ready!"
						description={
							<>
								Please follow{' '}
								<Link
									href={DOC_LINKS.LICENSE_KEY_SETUP_GUIDE}
									isExternal
								>
									this guide here{' '}
									<ExternalLinkIcon mx="2px" />{' '}
								</Link>
								to set up your license on your Rocket.Chat
								server.
								<br />
								<>
									Also you can find the license key in your
									email
								</>
							</>
						}
						type="success"
						showIcon
					/>
					<br />

					<CopyBlock
						language={'text'}
						text={licenseData.data?.data?.license}
						showLineNumbers={false}
						theme={dracula}
						codeBlock
						wrapLines
					/>
				</>
			)}
			{licenseData?.error?.message && (
				<>
					<Alert
						message="Something went wrong while generating your license key."
						description={
							<>
								<p>Error: {licenseData?.error?.message}</p>
								<br />
								<p>
									Sometimes the payment takes a while to go
									through, so please try again in a few
									minutes or check your email for license key.
									If you need any assistance then please{' '}
									<ExternalLink
										text="contact us"
										href="https://appsforchat.com/contact"
									/>{' '}
									.
								</p>
								<br />
							</>
						}
						type="error"
						showIcon
					/>
					<br />
					<Button type={'primary'} onClick={onClickRetry}>
						{' '}
						Retry generating license key
					</Button>
				</>
			)}
		</Modal>
	);
};
