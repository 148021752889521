import { Container } from '@chakra-ui/react';
import { Alert, Modal } from 'antd';
import { ExternalLink } from '../Links';
import { WorkspaceInfoForm } from '../forms/WorkspaceInfoForm';

type Props = {
	onWorkspaceInfoSaved: ({
		workspaceAddress,
	}: {
		workspaceAddress: string;
	}) => void;
	onCancel: () => void;
};

export const WorkspaceInfoModal = ({
	onWorkspaceInfoSaved,
	onCancel,
}: Props) => {
	return (
		<Modal
			open
			onCancel={onCancel}
			title="Please enter your workspace information"
			centered
			footer={null}
		>
			<Alert
				message="Please tell us about the Rocket.Chat workspace where you are going to use this license."
				type="info"
				showIcon
			/>
			<Container py={5}>
				<WorkspaceInfoForm
					onWorkspaceInfoSaved={onWorkspaceInfoSaved}
				/>
			</Container>
			<Alert
				message={
					<>
						Please note: Your license will be associated with the
						workspace Site_URL you enter above. Please make sure you
						enter the correct Site_URL as you{' '}
						<u>
							<b>will not be able to make changes later</b>
						</u>
						.<br />
						<br />
						If you have any questions about this, please{' '}
						<ExternalLink href="/contact" text="contact us" />
						before proceeding.
					</>
				}
				type="warning"
				showIcon
			/>
		</Modal>
	);
};
