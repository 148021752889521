import React from 'react';
import {
	Button,
	FormControl,
	FormErrorMessage,
	Input,
	useColorModeValue,
} from '@chakra-ui/react';
import { useField } from 'formik';

export const ChakraTextInput = ({
	...props
}: {
	label: string;
	name: string;
	type?: string;
	placeholder: string;
	id?: string;
}) => {
	props.type = props.type || 'text';

	const [field, meta] = useField<string>(props);

	const isError = meta.touched && meta.error ? true : false;

	return (
		<>
			<FormControl isInvalid={isError}>
				<Input
					color={useColorModeValue('gray.800', 'gray.200')}
					bg={useColorModeValue('gray.100', 'gray.600')}
					rounded={'full'}
					border={0}
					_focus={{
						bg: useColorModeValue('gray.200', 'gray.800'),
						outline: 'none',
					}}
					{...field}
					{...props}
				/>
				{isError ? (
					<FormErrorMessage>{meta.error}</FormErrorMessage>
				) : null}
			</FormControl>
		</>
	);
};

export const ChakraSubmitButton = ({
	btnText,
	isSubmitting,
}: {
	btnText: string;
	isSubmitting: boolean;
}) => {
	return (
		<Button
			bg={'blue.400'}
			rounded={'full'}
			color={'white'}
			flex={'1 0 auto'}
			_hover={{ bg: 'blue.500' }}
			_focus={{ bg: 'blue.500' }}
			type="submit"
			isLoading={isSubmitting}
		>
			{btnText}
		</Button>
	);
};
