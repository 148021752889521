import {
	Flex,
	chakra,
	Stack,
	Button,
	SimpleGrid,
	Box,
	Link,
	Text,
	List,
	ListIcon,
	ListItem,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { FaCheckCircle, FaWindowClose } from 'react-icons/fa';
import { useModal } from 'react-modal-hook';
import { Modal } from 'antd';

import { showPaymentFailedModal } from '../../../components/Modals/PaymentFailedModal';
import {
	MONTHLY_PLAN_PRICE,
	YEARLY_PLAN_PRICE,
} from '../../../constants/Paddle';
import { WorkspaceInfoModal } from '../../../components/Modals/WorkspaceInfoModal';
import {
	PaddlePassthrough,
	PaddleSuccessCheckout,
} from '../../../definitions/paddle';
import { PaymentConfirmModal } from '../../../components/Modals/PaymentConfirmModal';
import { CONSTANTS } from '../../../constants/generalConstants';
import { EnvVariables } from '../../../lib/envVariables';
import { useIsMobile } from '../../../hooks/useIsMobile';

const colors = {
	brand: {
		50: '#ecefff',
		100: '#cbceeb',
		200: '#a9aed6',
		300: '#888ec5',
		400: '#666db3',
		500: '#4d5499',
		600: '#3c4178',
		700: '#2a2f57',
		800: '#181c37',
		900: '#080819',
	},
};
const config = {
	initialColorMode: 'light',
	useSystemColorMode: false,
};

const theme = extendTheme({ colors, config });

export const NewPricing = () => {
	const [frequency, setFrequency] = useState('year');

	const isMobile = useIsMobile();

	const [successCheckoutMetadata, setSuccessCheckoutMetadata] = useState<{
		plan: 'monthly' | 'yearly';
		checkoutId: string;
	} | null>(null);

	const [showPaymentConfirmModal, hidePaymentConfirmModal] = useModal(() => {
		if (!successCheckoutMetadata) {
			throw new Error('No success checkout metadata found');
		}
		return (
			<PaymentConfirmModal
				onClose={hidePaymentConfirmModal}
				plan={successCheckoutMetadata.plan}
				checkoutId={successCheckoutMetadata.checkoutId}
			/>
		);
	}, [successCheckoutMetadata]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const Paddle = (window as any).Paddle;
	const triggerPaddleCheckout = useCallback(
		(workspaceAddress: string) => {
			const passthrough: PaddlePassthrough = {
				workspaceAddress,
			};
			Paddle.Checkout.open({
				product:
					frequency === 'year'
						? EnvVariables.REACT_APP_PADDLE_PLAN_YEARLY_ID
						: EnvVariables.REACT_APP_PADDLE_PLAN_MONTHLY_ID,
				passthrough: JSON.stringify(passthrough),
				successCallback: (data: PaddleSuccessCheckout) => {
					console.debug('Paddle success', data);

					const {
						checkout: { id: checkoutId },
					} = data;

					const plan = frequency === 'year' ? 'yearly' : 'monthly';

					setSuccessCheckoutMetadata({
						plan,
						checkoutId,
					});

					showPaymentConfirmModal();
				},
				closeCallback: () => {
					showPaymentFailedModal();
				},
			});
		},
		[frequency],
	);

	const saveWorkspaceAddressAndTriggerPaddleCheckout = useCallback(
		({ workspaceAddress }: { workspaceAddress: string }) => {
			hideWorkspaceInfoModal();
			triggerPaddleCheckout(workspaceAddress);
		},
		[triggerPaddleCheckout],
	);

	const [showWorkspaceInfoModal, hideWorkspaceInfoModal] = useModal(
		() => (
			<WorkspaceInfoModal
				onCancel={hideWorkspaceInfoModal}
				onWorkspaceInfoSaved={
					saveWorkspaceAddressAndTriggerPaddleCheckout
				}
			/>
		),
		[saveWorkspaceAddressAndTriggerPaddleCheckout],
	);

	const handleCheckout = useCallback(() => {
		if (isMobile) {
			Modal.error({
				title: 'Please switch to desktop',
				content:
					'Sorry, but we currently only support desktop payments. Please switch to desktop and try again.',
			});
			return;
		}

		showWorkspaceInfoModal();
	}, [showWorkspaceInfoModal, isMobile]);

	return (
		<ChakraProvider theme={theme}>
			<Flex
				w="full"
				_dark={{
					bg: '#3e3e3e',
				}}
				p={50}
				alignItems="center"
				justifyContent="center"
			>
				<Box
					py="64px"
					px="10"
					bg="gray.100"
					_dark={{
						bg: 'gray.700',
					}}
				>
					<Box w="full" px={[10, 4]} mx="auto" textAlign="center">
						<Text
							mb={2}
							fontSize="5xl"
							fontWeight="bold"
							lineHeight="tight"
						>
							Plans & Pricing
						</Text>
						<chakra.p
							mb={6}
							fontSize={['lg', 'xl']}
							color="gray.600"
							_dark={{
								color: 'gray.400',
							}}
						></chakra.p>
						<Flex justify="center" mx={['auto', 0]} mb={-2}>
							<Stack
								direction="row"
								justify="space-between"
								p="2"
								textAlign="center"
								rounded="md"
								bg="gray.200"
								_dark={{
									bg: 'gray.600',
								}}
							>
								<Button
									colorScheme="brand"
									variant={
										frequency === 'month'
											? 'solid'
											: 'ghost'
									}
									onClick={() => setFrequency('month')}
									px={6}
								>
									Bill Monthly
								</Button>
								<Button
									colorScheme="brand"
									variant={
										frequency === 'year' ? 'solid' : 'ghost'
									}
									onClick={() => setFrequency('year')}
									px={6}
								>
									Bill Yearly
								</Button>
							</Stack>
						</Flex>
					</Box>
					<Box maxW="7xl" py="20" mx="auto">
						<SimpleGrid columns={[1, 2]} gap={[16, 8]}>
							<Box
								rounded={['none', 'lg']}
								shadow={['none', 'md']}
								bg="white"
								_dark={{
									bg: 'gray.800',
								}}
							>
								<Flex
									direction="column"
									justify="space-between"
									p="6"
									borderBottomWidth="1px"
									color="gray.200"
									_dark={{
										color: 'gray.600',
									}}
								>
									<chakra.p
										mb={1}
										fontSize="lg"
										fontWeight="semibold"
										color="gray.700"
										_dark={{
											color: 'gray.400',
										}}
									>
										Free
									</chakra.p>
									<Text
										mb={2}
										fontSize="5xl"
										fontWeight={['bold', 'extrabold']}
										color="gray.900"
										_dark={{
											color: 'gray.50',
										}}
										lineHeight="tight"
									>
										${frequency === 'month' ? 0 : 0}
										<chakra.span
											fontSize="2xl"
											fontWeight="medium"
											color="gray.600"
											_dark={{
												color: 'gray.400',
											}}
										>
											{' '}
											/month
										</chakra.span>
									</Text>
									<Link
										w={['full', 'auto']}
										display="inline-flex"
										alignItems="center"
										justifyContent="center"
										px={5}
										py={3}
										border="solid"
										fontWeight="bold"
										rounded="md"
										shadow="md"
										_light={{
											color: 'gray.800',
										}}
										bg="gray.50"
										_dark={{
											bg: 'gray.600',
										}}
										_hover={{
											bg: 'gray.100',
											_dark: {
												bg: 'gray.700',
											},
										}}
										href={CONSTANTS.REMINDER_APP_DOCS_URL}
										isExternal
									>
										Get Started for Free
									</Link>
								</Flex>

								<List spacing={3} textAlign="start" p={12}>
									<ListItem>
										<ListIcon
											as={FaCheckCircle}
											color="green.500"
										/>
										Upto 5 Active Reminders per user
									</ListItem>
									<ListItem>
										<ListIcon
											as={FaCheckCircle}
											color="green.500"
										/>
										Unlimited Users
									</ListItem>
									<ListItem>
										<ListIcon
											as={FaWindowClose}
											color="red.500"
										/>
										Reminders for Channels
									</ListItem>
									<ListItem>
										<ListIcon
											as={FaWindowClose}
											color="red.500"
										/>
										Reminders for Users
									</ListItem>
									<ListItem>
										<ListIcon
											as={FaWindowClose}
											color="red.500"
										/>
										Setup Recurring Reminders
									</ListItem>
								</List>
							</Box>

							<Box
								rounded={['none', 'lg']}
								shadow={['none', 'md']}
								bg="white"
								_dark={{
									bg: 'gray.800',
								}}
							>
								<Flex
									direction="column"
									justify="space-between"
									p="6"
									borderBottomWidth="1px"
									color="gray.200"
									_dark={{
										color: 'gray.600',
									}}
								>
									<chakra.p
										mb={1}
										fontSize="lg"
										fontWeight="semibold"
										color="gray.700"
										_dark={{
											color: 'gray.400',
										}}
									>
										Premium
									</chakra.p>
									<Text
										mb={2}
										fontSize="5xl"
										fontWeight={['bold', 'extrabold']}
										color="gray.900"
										_dark={{
											color: 'gray.50',
										}}
										lineHeight="tight"
									>
										$
										{frequency === 'month'
											? MONTHLY_PLAN_PRICE
											: YEARLY_PLAN_PRICE}
										<chakra.span
											fontSize="2xl"
											fontWeight="medium"
											color="gray.600"
											_dark={{
												color: 'gray.400',
											}}
										>
											{' '}
											/month
										</chakra.span>
										<chakra.span
											fontSize="md"
											fontWeight="medium"
											color="gray.600"
											_dark={{
												color: 'gray.400',
											}}
										>
											{' '}
											(plus tax)
										</chakra.span>
									</Text>
									<Link
										w={['full', 'auto']}
										display="inline-flex"
										alignItems="center"
										justifyContent="center"
										px={5}
										py={3}
										border="solid transparent"
										fontWeight="bold"
										rounded="md"
										shadow="md"
										_light={{
											color: 'white',
										}}
										bg="brand.600"
										_dark={{
											bg: 'brand.500',
										}}
										_hover={{
											bg: 'brand.700',
											_dark: {
												bg: 'brand.600',
											},
										}}
										onClick={() => {
											handleCheckout();
										}}
									>
										Buy now
									</Link>
								</Flex>
								<List spacing={3} textAlign="start" p={12}>
									<ListItem>
										<ListIcon
											as={FaCheckCircle}
											color="green.500"
										/>
										Everything in Free Plan
									</ListItem>
									<ListItem>
										<ListIcon
											as={FaCheckCircle}
											color="green.500"
										/>
										Unlimited Active Reminders
									</ListItem>
									<ListItem>
										<ListIcon
											as={FaCheckCircle}
											color="green.500"
										/>
										Reminders for Channels
									</ListItem>
									<ListItem>
										<ListIcon
											as={FaCheckCircle}
											color="green.500"
										/>
										Reminders for other Users
									</ListItem>
									<ListItem>
										<ListIcon
											as={FaCheckCircle}
											color="green.500"
										/>
										Setup Recurring Reminders
									</ListItem>
								</List>
							</Box>
						</SimpleGrid>
					</Box>
				</Box>
			</Flex>
		</ChakraProvider>
	);
};
