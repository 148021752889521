import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
	Box,
	chakra,
	Container,
	Link,
	SimpleGrid,
	Stack,
	Text,
	VisuallyHidden,
	useColorModeValue,
	Heading,
	Button,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { CONSTANTS } from '../constants/generalConstants';

const SocialButton = ({
	children,
	label,
	href,
}: {
	children: ReactNode;
	label: string;
	href: string;
}) => {
	return (
		<chakra.button
			bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
			rounded={'full'}
			w={8}
			h={8}
			cursor={'pointer'}
			as={'a'}
			href={href}
			display={'inline-flex'}
			alignItems={'center'}
			justifyContent={'center'}
			transition={'background 0.3s ease'}
			_hover={{
				bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
			}}
		>
			<VisuallyHidden>{label}</VisuallyHidden>
			{children}
		</chakra.button>
	);
};

const ListHeader = ({ children }: { children: ReactNode }) => {
	return (
		<Text fontWeight={'500'} fontSize={'lg'} mb={2}>
			{children}
		</Text>
	);
};

export default function Footer() {
	const navigate = useNavigate();

	return (
		<Box
			bg={useColorModeValue('gray.50', 'gray.900')}
			color={useColorModeValue('gray.700', 'gray.200')}
		>
			<Container as={Stack} maxW={'6xl'} py={10}>
				<SimpleGrid
					templateColumns={{
						sm: '1fr 1fr',
						md: '2fr 1fr 1fr 1fr 2fr',
					}}
					spacing={8}
				>
					<Stack spacing={6}>
						<Box>
							<Heading
								fontWeight={600}
								fontSize={{
									base: 'large',
									sm: 'large',
									md: 'large',
								}}
								lineHeight={'110%'}
							>
								<Text as={'span'} color={'orange.400'}>
									Apps
								</Text>
								<Text as={'span'} color={'blue.400'}>
									For
								</Text>
								<Text as={'span'} color={'green.400'}>
									Chat
								</Text>
							</Heading>
						</Box>
						<Text fontSize={'sm'}>
							© {new Date().getFullYear()} AppsForChat. All rights
							reserved
						</Text>
						<Stack direction={'row'} spacing={6}>
							{/* <SocialButton label={'Twitter'} href={'#'}>
								<FaTwitter />
							</SocialButton>
							<SocialButton label={'YouTube'} href={'#'}>
								<FaYoutube />
							</SocialButton>
							<SocialButton label={'Instagram'} href={'#'}>
								<FaInstagram />
							</SocialButton> */}
							<span>
								Made with ❤️ in 🇮🇳 by{' '}
								<Link
									href={
										'https://www.linkedin.com/in/murtaza-patrawala-b17419166/'
									}
									isExternal
								>
									Murtaza <ExternalLinkIcon mx="2px" />
								</Link>
							</span>
						</Stack>
					</Stack>
					<Stack align={'flex-start'}>
						<ListHeader>Product</ListHeader>
						<Link href={'/reminder-app'}>Reminder Bot</Link>
						<Link href={CONSTANTS.REMINDER_APP_DOCS_URL} isExternal>
							Docs for Reminder Bot
						</Link>
					</Stack>
					<Stack align={'flex-start'}>
						<ListHeader>Support</ListHeader>
						<Link href={'/contact'}>Contact us</Link>
						<Link href={'/newsletter'}>Newsletter</Link>
					</Stack>
					<Stack align={'flex-start'}>
						<ListHeader>Legal</ListHeader>
						<Link href={'/terms'}>Terms of Use</Link>
						<Link href={'/privacy'}>Privacy Policy</Link>
						<Link href={'/eula'}>License Agreement</Link>
					</Stack>
					<Stack align={'flex-start'}>
						<ListHeader>Want to be kept in the loop?</ListHeader>
						<Stack
							direction={'column'}
							as={'form'}
							spacing={'12px'}
						>
							<Button
								colorScheme="blue"
								size="md"
								onClick={() => navigate('/newsletter')}
							>
								Sign up for our newsletter
							</Button>
						</Stack>
					</Stack>
				</SimpleGrid>
			</Container>
		</Box>
	);
}
