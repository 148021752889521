import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Link } from '@chakra-ui/react';

type Props = {
	href: string;
	text: string;
};

export const ExternalLink = ({ href, text }: Props) => (
	<Link href={href} isExternal>
		{text} <ExternalLinkIcon mx="2px" />{' '}
	</Link>
);

export const redirectToExternalLink = (link: string) => {
	window.open(link, '_blank');
};
