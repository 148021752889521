import { Container, Stack, Heading, Box, Text, Button } from '@chakra-ui/react';
import { useModal } from 'react-modal-hook';
import { TrialModal } from '../../../components/Modals/TrialModal';

export const RemindTrial = () => {
	const [showTrialModal, hideTrialModal] = useModal(() => (
		<TrialModal onClose={hideTrialModal} />
	));

	return (
		<Container maxW={'5xl'} shadow="xl">
			<Stack
				as={Box}
				textAlign={'center'}
				spacing={{ base: 8, md: 14 }}
				py={{ base: 20, md: 36 }}
			>
				<Heading
					fontWeight={600}
					fontSize={{ base: '2xl', sm: '4xl', md: '5xl' }}
					lineHeight={'110%'}
				>
					Start your free trial today
				</Heading>
				<Text
					color={'gray.500'}
					fontSize={{
						base: 'lg',
						md: 'xl',
					}}
				>
					Explore all the premium features of Reminder App for 1 month
					and see how it can help you and your team. <br />
					No credit card required.
				</Text>
				<Stack
					direction={'column'}
					spacing={3}
					align={'center'}
					alignSelf={'center'}
					position={'relative'}
				>
					<Button
						colorScheme={'green'}
						bg={'green.400'}
						rounded={'full'}
						px={6}
						_hover={{
							bg: 'green.500',
						}}
						onClick={showTrialModal}
					>
						Start free trial
					</Button>
				</Stack>
			</Stack>
		</Container>
	);
};
