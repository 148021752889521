export enum CONSTANTS {
	CONTACT_EMAIL = 'contact@appsforchat.com',
	GITHUB_URL = 'https://github.com/AddReminders/Reminders-in-RocketChat/issues',
	REMINDER_APP_DOCS_URL = 'https://docs.appsforchat.com/reminder-bot',
	INSTATUS_APP_GITHUB_URL = 'https://github.com/murtaza98/Instatus-RocketChat-App',
	CHATGPT_APP_GITHUB_URL = 'https://github.com/murtaza98/ChatGPT-for-Rocket.Chat',
}

export enum DOC_LINKS {
	ACTIVE_REMINDERS = 'https://docs.appsforchat.com/reminder-bot/guides/managing-reminders/mark-a-reminder-as-complete',
	LICENSE_AGREEMENT = 'https://docs.appsforchat.com/reminder-bot/legal/license-agreement',
	PRIVACY_POLICY = 'https://docs.appsforchat.com/reminder-bot/legal/privacy-policy',
	TERMS_OF_USE = 'https://docs.appsforchat.com/reminder-bot/legal/terms-of-use',
	SITE_URL_HELP = 'https://docs.appsforchat.com/reminder-bot/guides/premium-license/where-to-find-workspace-site_url-for-license',
	LICENSE_KEY_SETUP_GUIDE = 'https://docs.appsforchat.com/reminder-bot/guides/premium-license/how-to-set-up-your-license-on-rocket.chat-server',
	HOW_TO_PURCHASE_LICENSE = 'https://docs.appsforchat.com/reminder-bot/guides/premium-license/how-to-purchase-a-premium-license',
}
