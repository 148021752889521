import { Modal } from 'antd';
import { ExternalLink } from '../Links';

export const showPaymentFailedModal = () => {
	return Modal.error({
		title: 'Your payment request has failed',
		content: (
			<>
				Sorry, something went wrong. Please try again or{' '}
				<ExternalLink href="/contact" text="Contact Us" />
			</>
		),
	});
};
