import {
	Container,
	SimpleGrid,
	Flex,
	Heading,
	Text,
	Stack,
	StackDivider,
	Icon,
	useColorModeValue,
	useBreakpointValue,
	Button,
} from '@chakra-ui/react';
import { IoLockClosed } from 'react-icons/io5';
import { FaRocketchat, FaRocket } from 'react-icons/fa';
import { BiHappyBeaming } from 'react-icons/bi';
import { ReactElement } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Pagination, Navigation, Lazy, Autoplay } from 'swiper';
import { useNavigate } from 'react-router-dom';

import Promo1 from '../../assets/a4c-assets/reminder-promo-1.png';
import Promo2 from '../../assets/a4c-assets/reminder-promo-2.png';
import Promo3 from '../../assets/a4c-assets/reminder-promo-3.png';
import Promo4 from '../../assets/a4c-assets/reminder-promo-4.png';
import Promo5 from '../../assets/a4c-assets/reminder-promo-5.png';

interface FeatureProps {
	text: string;
	iconBg: string;
	icon?: ReactElement;
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
	return (
		<Stack direction={'row'} align={'center'}>
			<Flex
				w={8}
				h={8}
				align={'center'}
				justify={'center'}
				rounded={'full'}
				bg={iconBg}
			>
				{icon}
			</Flex>
			<Text fontWeight={600}>{text}</Text>
		</Stack>
	);
};

export default function ReminderAppDetails() {
	const navigate = useNavigate();
	return (
		<Container maxW={'5xl'} py={12} w={'100%'}>
			<SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} w={'100%'}>
				<Stack spacing={4}>
					<Text
						textTransform={'uppercase'}
						color={'blue.400'}
						fontWeight={600}
						fontSize={'sm'}
						bg={useColorModeValue('blue.50', 'blue.900')}
						p={2}
						alignSelf={'flex-start'}
						rounded={'md'}
					>
						Our Apps
					</Text>
					<Heading>Reminder App</Heading>
					<Text color={'gray.500'} fontSize={'lg'}>
						Create Reminders within Rocket.Chat
					</Text>
					<Stack
						spacing={4}
						divider={
							<StackDivider
								borderColor={useColorModeValue(
									'gray.100',
									'gray.700',
								)}
							/>
						}
					>
						<Feature
							icon={
								<Icon
									as={IoLockClosed}
									color={'yellow.500'}
									w={5}
									h={5}
								/>
							}
							iconBg={useColorModeValue(
								'yellow.100',
								'yellow.900',
							)}
							text={'Total control over your Data'}
						/>
						<Feature
							icon={
								<Icon
									as={FaRocketchat}
									color={'red.500'}
									w={5}
									h={5}
								/>
							}
							iconBg={useColorModeValue(
								'orange.100',
								'orange.900',
							)}
							text={'Fully integrated with Rocket.Chat'}
						/>
						<Feature
							icon={
								<Icon
									as={FaRocket}
									color={'green.500'}
									w={5}
									h={5}
								/>
							}
							iconBg={useColorModeValue('green.100', 'green.900')}
							text={
								'Designed with end users in mind to accelerate work'
							}
						/>
						<Feature
							icon={
								<Icon
									as={BiHappyBeaming}
									color={'purple.500'}
									w={5}
									h={5}
								/>
							}
							iconBg={useColorModeValue(
								'purple.100',
								'purple.900',
							)}
							text={'Trusted by over 25 thousand users'}
						/>
					</Stack>
				</Stack>
				<Flex>
					<>
						<Swiper
							lazy={true}
							pagination={{
								clickable: true,
							}}
							navigation={true}
							modules={[Lazy, Pagination, Navigation, Autoplay]}
							className="mySwiper"
							autoplay={{
								delay: 2500,
								disableOnInteraction: false,
							}}
							loop={true}
							style={{
								marginTop: useBreakpointValue({
									base: '25px',
									md: '125px',
								}),
								height: useBreakpointValue({
									base: '250px',
									md: '250px',
								}),
							}}
						>
							<SwiperSlide>
								<img
									src={Promo1}
									className="swiper-lazy"
									alt="Its time to stop forgetting!"
								/>
							</SwiperSlide>
							<SwiperSlide>
								<img
									src={Promo2}
									className="swiper-lazy"
									alt="Create reminders of important messages"
								/>
								<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
							</SwiperSlide>
							<SwiperSlide>
								<img
									src={Promo3}
									className="swiper-lazy"
									alt="Manage your reminders in Rocket.Chat"
								/>
								<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
							</SwiperSlide>
							<SwiperSlide>
								<img
									src={Promo4}
									className="swiper-lazy"
									alt="Interact with reminders in your own language - multi-language support"
								/>
								<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
							</SwiperSlide>
							<SwiperSlide>
								<img
									src={Promo5}
									className="swiper-lazy"
									alt="Setup recurring reminders"
								/>
								<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
							</SwiperSlide>
						</Swiper>
					</>
				</Flex>
			</SimpleGrid>
			<Button
				colorScheme="blue"
				variant="solid"
				my={10}
				onClick={() => navigate('/reminder-app')}
			>
				Click here to Learn More
			</Button>
		</Container>
	);
}
