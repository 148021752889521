import { Flex, chakra, SimpleGrid, Box, Icon } from '@chakra-ui/react';
import { IoLockClosed } from 'react-icons/io5';

export const RemindAppFeatures = () => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const Feature = (props: any) => {
		return (
			<Box>
				<Flex
					alignItems="center"
					justifyContent="center"
					w={8}
					h={8}
					mb={4}
					rounded="full"
					color={`${props.color}.600`}
					_dark={{
						color: `${props.color}.100`,
					}}
					bg={`${props.color}.100`}
				>
					{props.icon}
				</Flex>
				<chakra.h3
					mb={2}
					fontWeight="semibold"
					lineHeight="shorter"
					_light={{
						color: 'gray.900',
					}}
				>
					{props.title}
				</chakra.h3>
				<chakra.p
					fontSize="sm"
					color="gray.500"
					_dark={{
						color: 'gray.400',
					}}
				>
					{props.children}
				</chakra.p>
			</Box>
		);
	};

	return (
		<Flex p={20} w="auto" justifyContent="center" alignItems="center">
			<Box
				px={8}
				py={20}
				mx="auto"
				bg="white"
				_dark={{
					bg: 'gray.800',
				}}
				shadow="xl"
			>
				<Box
					textAlign={{
						lg: 'center',
					}}
				>
					<chakra.p
						mt={2}
						fontSize={{
							base: '3xl',
							sm: '4xl',
						}}
						lineHeight="8"
						fontWeight="extrabold"
						letterSpacing="tight"
						_light={{
							color: 'gray.900',
						}}
					>
						Features
					</chakra.p>
					<chakra.p
						mt={4}
						maxW="2xl"
						fontSize="xl"
						mx={{
							lg: 'auto',
						}}
						color="gray.500"
						_dark={{
							color: 'gray.400',
						}}
					>
						Want to know what makes RemindApp so great? Here are
						some of the features that make RemindApp the best app
						for reminding you of things on RocketChat
					</chakra.p>
				</Box>
				<SimpleGrid
					columns={{
						base: 1,
						sm: 2,
						md: 3,
						lg: 3,
					}}
					spacingX={{
						base: 16,
						lg: 24,
					}}
					spacingY={20}
					mt={6}
				>
					<Feature
						color="yellow"
						title="Total control over your data"
						icon={
							<Icon
								as={IoLockClosed}
								color={'yellow.500'}
								w={5}
								h={5}
							/>
						}
					>
						All your user's data for this app is stored in your own
						Rocket.Chat database so you have full control over it.
					</Feature>

					<Feature
						color="yellow"
						title="Don't lose track of important messages"
						icon={
							<path
								fillRule="evenodd"
								d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"
								clipRule="evenodd"
							/>
						}
					>
						Seen a message you want to respond to later? Just setup
						a reminder for it and the bot will ping you when it's
						time to respond.
					</Feature>
					<Feature
						color="brand"
						title="Automate routine messages with Recurring Reminders"
						icon={
							<path
								fillRule="evenodd"
								d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
								clipRule="evenodd"
							/>
						}
					>
						Reminder App allows you to automate routine messages by
						setting up recurring reminders that will be sent to your
						team members at the specified time. For example, you can
						set up a reminder in your team channel to remind your
						team members to fill out their timesheets every Friday
						at 5pm.
					</Feature>

					<Feature
						color="blue"
						title="Multi-language support"
						icon={
							<path
								fillRule="evenodd"
								d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
								clipRule="evenodd"
							/>
						}
					>
						This app is available in English, Portuguese, Polish,
						German and Russian. More languages are added regularly
						on request and we're always open to contributions.
					</Feature>

					<Feature
						color="purple"
						title="Make your team more productive"
						icon={
							<>
								<path
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path d="M10 3.5a1.5 1.5 0 013 0V4a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-.5a1.5 1.5 0 000 3h.5a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5a1.5 1.5 0 00-3 0v.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 00-1-1h-.5a1.5 1.5 0 010-3H4a1 1 0 001-1V6a1 1 0 011-1h3a1 1 0 001-1v-.5z" />
								</path>
							</>
						}
					>
						Reminder App will help you and your team stay on top of
						things. You'll be able to see what's coming up and
						what's overdue, and you'll be able to see what's
						important to your team.
					</Feature>

					<Feature
						color="green"
						title="Fully integrated with Rocket.Chat"
						icon={
							<>
								<path
									fillRule="evenodd"
									d="M6.625 2.655A9 9 0 0119 11a1 1 0 11-2 0 7 7 0 00-9.625-6.492 1 1 0 11-.75-1.853zM4.662 4.959A1 1 0 014.75 6.37 6.97 6.97 0 003 11a1 1 0 11-2 0 8.97 8.97 0 012.25-5.953 1 1 0 011.412-.088z"
									clipRule="evenodd"
								/>
								<path
									fillRule="evenodd"
									d="M5 11a5 5 0 1110 0 1 1 0 11-2 0 3 3 0 10-6 0c0 1.677-.345 3.276-.968 4.729a1 1 0 11-1.838-.789A9.964 9.964 0 005 11zm8.921 2.012a1 1 0 01.831 1.145 19.86 19.86 0 01-.545 2.436 1 1 0 11-1.92-.558c.207-.713.371-1.445.49-2.192a1 1 0 011.144-.83z"
									clipRule="evenodd"
								/>
								<path
									fillRule="evenodd"
									d="M10 10a1 1 0 011 1c0 2.236-.46 4.368-1.29 6.304a1 1 0 01-1.838-.789A13.952 13.952 0 009 11a1 1 0 011-1z"
									clipRule="evenodd"
								/>
							</>
						}
					>
						This app is built to work seamlessly with Rocket.Chat.
					</Feature>
					<Feature
						color="purple"
						title="One click install"
						icon={
							<path
								fillRule="evenodd"
								d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
								clipRule="evenodd"
							/>
						}
					>
						You only need to install the app once and you're ready
						to go.
					</Feature>

					<Feature
						color="pink"
						title="Your team will love it"
						icon={
							<path
								fillRule="evenodd"
								d="M7 2a1 1 0 00-.707 1.707L7 4.414v3.758a1 1 0 01-.293.707l-4 4C.817 14.769 2.156 18 4.828 18h10.343c2.673 0 4.012-3.231 2.122-5.121l-4-4A1 1 0 0113 8.172V4.414l.707-.707A1 1 0 0013 2H7zm2 6.172V4h2v4.172a3 3 0 00.879 2.12l1.027 1.028a4 4 0 00-2.171.102l-.47.156a4 4 0 01-2.53 0l-.563-.187a1.993 1.993 0 00-.114-.035l1.063-1.063A3 3 0 009 8.172z"
								clipRule="evenodd"
							/>
						}
					>
						Built from the ground up to reduce stress for all
						involved, from eliminating the need for intense DMs to
						built in etiquette like honoring local time zones.
						Reminder App will free you up from annoying
						administrative exchanges so that you can support your
						team in meaningful ways.
					</Feature>

					<Feature
						color="pink"
						title="Inbuilt mechanism to Backup and Restore all your Reminders"
						icon={
							<path
								fillRule="evenodd"
								d="M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z"
								clipRule="evenodd"
							/>
						}
					>
						You can backup all your reminders and restore them in
						case of any data loss or outages.
					</Feature>
				</SimpleGrid>
			</Box>
		</Flex>
	);
};
