import React from 'react';
import { Form, Input, SubmitButton, Checkbox } from 'formik-antd';
import { Formik } from 'formik';
import { ExternalLink } from '../Links';
import { DOC_LINKS } from '../../constants/generalConstants';

type ContactFormData = {
	email: string;
	contactName: string;
	message: string;
	agreement: boolean;
};

type Props = {
	onContactMsgSaved: (
		{ message, email, contactName }: Omit<ContactFormData, 'agreement'>,
		finishLoading: () => void,
		resetForm: () => void,
	) => void;
};

export const ContactMsgForm = ({ onContactMsgSaved }: Props) => {
	return (
		<Formik
			initialValues={
				{
					message: '',
					email: '',
					contactName: '',
					agreement: false,
				} as ContactFormData
			}
			validate={(values) => {
				const errors: Partial<{
					[k in keyof ContactFormData]: string;
				}> = {};

				// Validate message
				if (!values.message || !values.message.trim()) {
					errors.message = 'Required';
				}

				// Validate email
				if (!values.email || !values.email.trim()) {
					errors.email = 'Required';
				}
				// validate if email is valid
				else if (
					!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
						values.email,
					)
				) {
					errors.email = 'Invalid email address';
				}

				// Validate contact name
				if (!values.contactName || !values.contactName.trim()) {
					errors.contactName = 'Required';
				}

				// Validate agreement
				if (!values.agreement) {
					errors.agreement = 'Please accept the agreement first';
				}

				return errors;
			}}
			render={() => (
				<Form
					name="basic"
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					labelAlign="left"
					style={{
						width: '100%',
					}}
				>
					<Form.Item label="Your Name" name="contactName">
						<Input name="contactName" placeholder="Your Name" />
					</Form.Item>
					<Form.Item label="Your Email" name="email">
						<Input name="email" placeholder="Email" />
					</Form.Item>
					<Form.Item label="Message" name="message">
						<Input.TextArea
							name="message"
							placeholder="Your message"
							style={{
								marginBottom: '10px',
							}}
						/>
					</Form.Item>

					<Form.Item
						name="agreement"
						style={{
							display: 'inline-block',
							width: '100%',
						}}
					>
						<Checkbox name="agreement">
							I agree to the{' '}
							<ExternalLink
								text="privacy policy"
								href={DOC_LINKS.PRIVACY_POLICY}
							/>{' '}
							of this site.
						</Checkbox>
					</Form.Item>

					<SubmitButton>Send Message</SubmitButton>
				</Form>
			)}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				onContactMsgSaved(
					values,
					() => {
						setSubmitting(false);
					},
					resetForm,
				);
			}}
		/>
	);
};
