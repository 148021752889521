import {
	Box,
	Container,
	Heading,
	SimpleGrid,
	Icon,
	Text,
	Stack,
	HStack,
	VStack,
	Link,
} from '@chakra-ui/react';
import { CheckIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { DOC_LINKS } from '../../../../constants/generalConstants';
import { ExternalLink } from '../../../../components/Links';

export default function PricingFAQ() {
	return (
		<Box p={4}>
			<Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
				<Heading fontSize={'3xl'}>FAQ about Pricing</Heading>
			</Stack>

			<Container maxW={'6xl'} mt={10}>
				<SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} spacing={10}>
					<HStack key={4} align={'top'}>
						<Box color={'green.400'} px={2}>
							<Icon as={CheckIcon} />
						</Box>
						<VStack align={'start'}>
							<Text fontWeight={600}>
								{'How do you define an active reminder?'}
							</Text>
							<Text color={'gray.600'}>
								{
									'An active reminder is a reminder that has not been marked as completed yet. Learn more about completed reminders'
								}{' '}
								<Link
									href={DOC_LINKS.ACTIVE_REMINDERS}
									isExternal
								>
									{'here'}
									<ExternalLinkIcon mx="2px" />
								</Link>
							</Text>
						</VStack>
					</HStack>
					<HStack key={4} align={'top'}>
						<Box color={'green.400'} px={2}>
							<Icon as={CheckIcon} />
						</Box>
						<VStack align={'start'}>
							<Text fontWeight={600}>
								{'What is the refund policy?'}
							</Text>
							<Text color={'gray.600'}>
								{
									"We offer refunds up to 14 days after your paid subscription has begun if you're not satisfied. If you get a refund, you will loose access to premium features of this app. To request a refund,"
								}{' '}
								<ExternalLink
									href="/contact"
									text="contact us"
								/>
								{
									' with the email address and "order number" from your purchase receipt that would have been emailed to you.'
								}
							</Text>
						</VStack>
					</HStack>
					<HStack key={8} align={'top'}>
						<Box color={'green.400'} px={2}>
							<Icon as={CheckIcon} />
						</Box>
						<VStack align={'start'}>
							<Text fontWeight={600}>
								{'How do I get a VAT refund?'}
							</Text>
							<Text color={'gray.600'}>
								{`Before the checkout process is complete, you should be given the option of entering a VAT ID which will exempt your purchase from a sales tax charge. If you think you've been incorrectly charged for VAT, please contact `}
								<ExternalLink
									href="mailto: help@paddle.com"
									text="help@paddle.com"
								/>
								{` and they will be able to issue a VAT refund to you. Paddle handle all our transactions and will be able to help you.`}
							</Text>
						</VStack>
					</HStack>
					<HStack key={3} align={'top'}>
						<Box color={'green.400'} px={2}>
							<Icon as={CheckIcon} />
						</Box>
						<VStack align={'start'}>
							<Text fontWeight={600}>
								{
									'Can I use the premium license on multiple Rocket.Chat workspaces?'
								}
							</Text>
							<Text color={'gray.600'}>
								{
									'No, you cannot re-use the license on any workspace other than the defined one. For more info, please check our'
								}{' '}
								<Link
									href={DOC_LINKS.LICENSE_AGREEMENT}
									isExternal
								>
									License Agreement{' '}
									<ExternalLinkIcon mx="2px" />
								</Link>
							</Text>
						</VStack>
					</HStack>
					<HStack key={5} align={'top'}>
						<Box color={'green.400'} px={2}>
							<Icon as={CheckIcon} />
						</Box>
						<VStack align={'start'}>
							<Text fontWeight={600}>
								{'What are my payment options?'}
							</Text>
							<Text color={'gray.600'}>
								{
									'Transactions are handled by our payment provider Paddle. Paddle accepts cards or PayPal.'
								}
							</Text>
						</VStack>
					</HStack>
					<HStack key={8} align={'top'}>
						<Box color={'green.400'} px={2}>
							<Icon as={CheckIcon} />
						</Box>
						<VStack align={'start'}>
							<Text fontWeight={600}>
								{'How do I cancel my subscription?'}
							</Text>
							<Text color={'gray.600'}>
								{
									'If you may decide to cancel your subscription, then please'
								}{' '}
								<ExternalLink
									href="/contact"
									text="contact us"
								/>
								{
									' with the email address and "order number" from your purchase receipt that would have been emailed to you.'
								}
							</Text>
						</VStack>
					</HStack>
					<HStack key={8} align={'top'}>
						<Box color={'green.400'} px={2}>
							<Icon as={CheckIcon} />
						</Box>
						<VStack align={'start'}>
							<Text fontWeight={600}>
								{'What happens if I cancel my subscription?'}
							</Text>
							<Text color={'gray.600'}>
								{`If you cancel your subscription, the features your subscription unlocked will be unavailable to you after the time you've already paid for has expired. For example, if you purchase a yearly plan and cancel 9 months later, you'll lose access to the paid features 3 months after the cancellation date. You can cancel your subscription at any time.`}{' '}
							</Text>
						</VStack>
					</HStack>

					<HStack key={9} align={'top'}>
						<Box color={'green.400'} px={2}>
							<Icon as={CheckIcon} />
						</Box>
						<VStack align={'start'}>
							<Text fontWeight={600}>{'Other questions?'}</Text>
							<Text color={'gray.600'}>
								{
									'If you have any other questions, please do not hesitate to '
								}{' '}
								<Link href={'/contact'} isExternal>
									contact us <ExternalLinkIcon mx="2px" />
								</Link>{' '}
							</Text>
						</VStack>
					</HStack>
				</SimpleGrid>
			</Container>
		</Box>
	);
}
