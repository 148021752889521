import {
	Box,
	Button,
	CloseButton,
	Container,
	Icon,
	Square,
	Stack,
	Text,
	useBreakpointValue,
} from '@chakra-ui/react';
import { FiInfo } from 'react-icons/fi';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const ChatgptLaunch = () => {
	const navigate = useNavigate();
	const isMobile = useBreakpointValue({ base: true, md: false });

	const [isBannerVisible, setIsBannerVisible] = useState(true);

	return (
		<>
			{isBannerVisible && (
				<Box as="section" pb={{ base: '12', md: '24' }}>
					<Box bg="bg-surface" boxShadow="sm">
						<Container
							py={{ base: '4', md: '2.5' }}
							position="relative"
							borderRadius="md"
							backgroundColor={'orange'}
						>
							<CloseButton
								display={{ sm: 'none' }}
								position="absolute"
								right="2"
								top="2"
							/>
							<Stack
								direction={{ base: 'column', sm: 'row' }}
								justify="space-between"
								spacing={{ base: '3', md: '2' }}
							>
								<Stack
									spacing="4"
									direction={{ base: 'column', md: 'row' }}
									align={{ base: 'start', md: 'center' }}
								>
									{!isMobile && (
										<Square
											size="12"
											bg="bg-subtle"
											borderRadius="md"
										>
											<Icon as={FiInfo} boxSize="6" />
										</Square>
									)}
									<Stack
										direction={{
											base: 'column',
											md: 'row',
										}}
										spacing={{ base: '0.5', md: '1.5' }}
										pe={{ base: '4', sm: '0' }}
									>
										<Text fontWeight="medium">
											We just launched a new product! -
											ChatGPT App for Rocket.Chat
										</Text>
									</Stack>
								</Stack>
								<Stack
									direction={{ base: 'column', sm: 'row' }}
									spacing={{ base: '3', sm: '2' }}
									align={{ base: 'stretch', sm: 'center' }}
								>
									<Button
										variant="primary"
										width="full"
										onClick={() => {
											navigate('/chatgpt-app');
										}}
									>
										Learn More
									</Button>
									<CloseButton
										display={{
											base: 'none',
											sm: 'inline-flex',
										}}
										onClick={() => {
											setIsBannerVisible(false);
										}}
									/>
								</Stack>
							</Stack>
						</Container>
					</Box>
				</Box>
			)}
		</>
	);
};
