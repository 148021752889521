import React from 'react';
import { Formik, Form, FormikProps } from 'formik';
import { object, string } from 'yup';
import { Stack } from '@chakra-ui/react';
import {
	ChakraSubmitButton,
	ChakraTextInput,
} from './ChakraUiFormInputs.tsx/main';

type NewsletterFormData = {
	email: string;
};

type Props = {
	handleNewsletterSubscribed: (
		{ email }: NewsletterFormData,
		finishLoading: () => void,
		resetForm: () => void,
	) => void;
};

export const NewsletterForm = ({ handleNewsletterSubscribed }: Props) => {
	return (
		<>
			<Formik
				initialValues={
					{
						email: '',
					} as NewsletterFormData
				}
				validationSchema={object({
					email: string()
						.email('Invalid email address')
						.required('Please enter your email address'),
				})}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					handleNewsletterSubscribed(
						values,
						() => setSubmitting(false),
						resetForm,
					);
				}}
			>
				{(formik: FormikProps<NewsletterFormData>) => (
					<Form
						style={{
							width: '100%',
						}}
					>
						<Stack
							spacing={4}
							direction={{ base: 'column', md: 'row' }}
							w={'full'}
						>
							<ChakraTextInput
								label="Email Address"
								name="email"
								type="email"
								placeholder="Your email address"
							/>
							formik.iss
							<ChakraSubmitButton
								btnText="Submit"
								isSubmitting={formik.isSubmitting}
							/>
						</Stack>
					</Form>
				)}
			</Formik>
		</>
	);
};
