import {
	Container,
	SimpleGrid,
	Flex,
	Heading,
	Text,
	Stack,
	StackDivider,
	Icon,
	useColorModeValue,
	Button,
	useBreakpointValue,
} from '@chakra-ui/react';
import { ReactElement } from 'react';
import { VscHubot } from 'react-icons/vsc';
import { ImPower } from 'react-icons/im';
import { FaRocket } from 'react-icons/fa';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Swiper, SwiperSlide } from 'swiper/react';

import { Pagination, Navigation, Lazy, Autoplay } from 'swiper';

import Promo1 from '../../assets/a4c-assets/chatgpt-promo-1.png';
import Promo2 from '../../assets/a4c-assets/chatgpt-promo-2.png';
import Promo3 from '../../assets/a4c-assets/chatgpt-promo-3.png';
import { useNavigate } from 'react-router-dom';

interface FeatureProps {
	text: string;
	iconBg: string;
	icon?: ReactElement;
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
	return (
		<Stack direction={'row'} align={'center'}>
			<Flex
				w={8}
				h={8}
				align={'center'}
				justify={'center'}
				rounded={'full'}
				bg={iconBg}
			>
				{icon}
			</Flex>
			<Text fontWeight={600}>{text}</Text>
		</Stack>
	);
};

export default function ChatGPTAppDetails() {
	const navigate = useNavigate();

	return (
		<Container maxW={'5xl'} py={12} w={'100%'}>
			<SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} w={'100%'}>
				<Stack spacing={4}>
					<div>
						<Heading>ChatGPT App</Heading>(New)
					</div>

					<Text color={'gray.500'} fontSize={'lg'}>
						ChatGPT integration with Rocket.Chat
					</Text>
					<Stack
						spacing={4}
						divider={
							<StackDivider
								borderColor={useColorModeValue(
									'gray.100',
									'gray.700',
								)}
							/>
						}
					>
						<Feature
							icon={
								<Icon
									as={FaRocket}
									color={'green.500'}
									w={5}
									h={5}
								/>
							}
							iconBg={useColorModeValue('green.100', 'green.900')}
							text={
								'Experience the power of AI in your chats with ChatGPT'
							}
						/>
						{/* Make messaging a breeze with your own AI Assistant */}
						<Feature
							icon={
								<Icon
									as={VscHubot}
									color={'black.500'}
									w={5}
									h={5}
								/>
							}
							iconBg={useColorModeValue('grey.100', 'grey.900')}
							text={
								'Make messaging a breeze with your own AI Assistant'
							}
						/>
						<Feature
							icon={
								<Icon
									as={ImPower}
									color={'purple.500'}
									w={5}
									h={5}
								/>
							}
							iconBg={useColorModeValue(
								'purple.100',
								'purple.900',
							)}
							text={
								'Ask ChatGPT to respond to messages, draft new messages, summarize conversations, and more'
							}
						/>
					</Stack>
				</Stack>
				<Flex>
					<>
						<Swiper
							lazy={true}
							pagination={{
								clickable: true,
							}}
							navigation={true}
							modules={[Lazy, Pagination, Navigation, Autoplay]}
							className="mySwiper"
							autoplay={{
								delay: 2500,
								disableOnInteraction: false,
							}}
							loop={true}
							style={{
								marginTop: useBreakpointValue({
									base: '25px',
									md: '125px',
								}),
								height: useBreakpointValue({
									base: '250px',
									md: '250px',
								}),
							}}
						>
							<SwiperSlide>
								<img
									src={Promo1}
									className="swiper-lazy"
									alt="Ask ChatGPT to respond to messages"
								/>
							</SwiperSlide>
							<SwiperSlide>
								<img
									src={Promo2}
									className="swiper-lazy"
									alt="Ask ChatGPT to summarize huge conversations"
								/>
								<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
							</SwiperSlide>
							<SwiperSlide>
								<img
									src={Promo3}
									className="swiper-lazy"
									alt="Ask ChatGPT to draft new messages"
								/>
								<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
							</SwiperSlide>
						</Swiper>
					</>
				</Flex>
			</SimpleGrid>
			<Button
				colorScheme="blue"
				variant="solid"
				my={10}
				onClick={() => navigate('/chatgpt-app')}
			>
				Click here for more info
			</Button>
		</Container>
	);
}
