import React from 'react';
import './App.css';
import { Routes, Route, Link } from 'react-router-dom';
import Login from './view/Login';
// import ManageLicensesHome from './view/ManageLicensesHome';
import SignUpCard from './view/Register';
import Home from './view/Home/HomeMain';
import NavBar from './view/NavBar';
import ReminderAppProduct from './view/Products/Reminder/main';
import ChatGPTAppProduct from './view/Products/ChatGPT/main';

import Contact from './view/Contact';
import { RedirectPage } from './view/RedirectPage';
import { DOC_LINKS } from './constants/generalConstants';
import Footer from './view/Footer';
import CookieConsent from 'react-cookie-consent';
import { ExternalLink } from './components/Links';
import { Newsletter } from './view/Newsletter/main';

function App() {
	return (
		<div>
			<NavBar />
			<Routes>
				<Route path="/">
					<Route index element={<Home />} />
					{/* <Route path="licenses" element={<ManageLicensesHome />} /> */}
					<Route path="contact" element={<Contact />} />
					<Route path="login" element={<Login />} />
					<Route path="register" element={<SignUpCard />} />
					<Route
						path="reminder-app"
						element={<ReminderAppProduct />}
					/>
					<Route path="chatgpt-app" element={<ChatGPTAppProduct />} />
					<Route path="newsletter" element={<Newsletter />} />
					<Route
						path="privacy"
						element={
							<RedirectPage url={DOC_LINKS.PRIVACY_POLICY} />
						}
					/>
					<Route
						path="terms"
						element={<RedirectPage url={DOC_LINKS.TERMS_OF_USE} />}
					/>
					<Route
						path="eula"
						element={
							<RedirectPage url={DOC_LINKS.LICENSE_AGREEMENT} />
						}
					/>
					<Route
						path="reminder-bot-license"
						element={
							<RedirectPage
								url={DOC_LINKS.HOW_TO_PURCHASE_LICENSE}
							/>
						}
					/>
					{/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
					<Route path="*" element={<NoMatch />} />
				</Route>
			</Routes>
			<Footer />
			<CookieConsent>
				By using this website, you agree to our use of cookies to
				deliver a better site experience. Learn more about our use of
				cookies in our{' '}
				<ExternalLink
					href={DOC_LINKS.PRIVACY_POLICY}
					text="privacy policy"
				/>
			</CookieConsent>
		</div>
	);
}

function NoMatch() {
	return (
		<div>
			<h2>Nothing to see here!</h2>
			<p>
				<Link to="/">Go to the home page</Link>
			</p>
		</div>
	);
}

export default App;
