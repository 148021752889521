import React from 'react';
import { Checkbox, Form, Input, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import { Link } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Alert, Modal } from 'antd';
import { DOC_LINKS } from '../../constants/generalConstants';
import { ExternalLink } from '../Links';

type WorkspaceInfoFormData = {
	workspaceAddress: string;
	agreement: boolean;
};

type Props = {
	onWorkspaceInfoSaved: ({
		workspaceAddress,
	}: {
		workspaceAddress: string;
	}) => void;
};

export const WorkspaceInfoForm = ({ onWorkspaceInfoSaved }: Props) => {
	return (
		<Formik
			initialValues={
				{
					workspaceAddress: '',
					agreement: false,
				} as WorkspaceInfoFormData
			}
			validate={(values) => {
				const errors: Partial<{
					[k in keyof WorkspaceInfoFormData]: string;
				}> = {};

				// Validate workspace address
				if (
					!values.workspaceAddress ||
					!values.workspaceAddress.trim()
				) {
					errors.workspaceAddress = 'Required';
				}

				// validate if workspace address is a valid URL
				try {
					new URL(values.workspaceAddress);
				} catch (error) {
					errors.workspaceAddress = 'Invalid URL';
				}

				// Validate agreement
				if (!values.agreement) {
					errors.agreement = 'Please accept the agreement first';
				}

				return errors;
			}}
			render={() => (
				<Form
					name="basic"
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					labelAlign="left"
					initialValues={{ remember: true }}
				>
					<Form.Item
						label="Workspace Site URL"
						name="workspaceAddress"
					>
						<Input
							name="workspaceAddress"
							placeholder="Site URL"
							style={{
								marginBottom: '10px',
							}}
						/>
						<Alert
							message={
								<>
									<Link
										href={DOC_LINKS.SITE_URL_HELP}
										isExternal
									>
										Click here <ExternalLinkIcon mx="2px" />{' '}
									</Link>
									for the guide on where to find this
									Site_URL.
									<br />
									Eg: https://mycompany.rocketchat.com/
									<br />
								</>
							}
							type="info"
							showIcon
						/>
					</Form.Item>

					<Form.Item
						name="agreement"
						style={{
							width: '100%',
						}}
					>
						<Checkbox name="agreement">
							I agree to{' '}
							<ExternalLink
								text="terms of use"
								href={DOC_LINKS.TERMS_OF_USE}
							/>{' '}
							and{' '}
							<ExternalLink
								text="license agreement"
								href={DOC_LINKS.LICENSE_AGREEMENT}
							/>{' '}
							of Reminder Bot of AppsForChat
						</Checkbox>
					</Form.Item>

					<SubmitButton> Save and proceed to payment </SubmitButton>
				</Form>
			)}
			onSubmit={(values, { setSubmitting }) => {
				const trimmedWorkspaceAddress = values.workspaceAddress.trim();

				Modal.confirm({
					title: 'Final confirmation',
					content: (
						<p>
							Are you sure this workspace Site_Url is correct?
							<br />
							<u>
								<b>{trimmedWorkspaceAddress}</b>
							</u>
							<br />
							<br />
							You won't be able to change it later.
						</p>
					),
					onOk: () => {
						onWorkspaceInfoSaved({
							workspaceAddress: trimmedWorkspaceAddress,
						});
					},
					onCancel: () => {
						setSubmitting(false);
					},
					okText: 'Yes, I am sure. Proceed',
					cancelText: 'No, let me change it',
				});
			}}
		/>
	);
};
