import {
	Container,
	SimpleGrid,
	Flex,
	Heading,
	Text,
	Stack,
	StackDivider,
	Icon,
	useColorModeValue,
	Button,
} from '@chakra-ui/react';
import { IoLockClosed } from 'react-icons/io5';
import { ReactElement } from 'react';
import { AiFillGithub } from 'react-icons/ai';
import { GiBullseye } from 'react-icons/gi';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { redirectToExternalLink } from '../../components/Links';
import { CONSTANTS } from '../../constants/generalConstants';

interface FeatureProps {
	text: string;
	iconBg: string;
	icon?: ReactElement;
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
	return (
		<Stack direction={'row'} align={'center'}>
			<Flex
				w={8}
				h={8}
				align={'center'}
				justify={'center'}
				rounded={'full'}
				bg={iconBg}
			>
				{icon}
			</Flex>
			<Text fontWeight={600}>{text}</Text>
		</Stack>
	);
};

export default function InStatusAppDetails() {
	return (
		<Container maxW={'5xl'} py={12} w={'100%'}>
			<SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} w={'100%'}>
				<Stack spacing={4}>
					<Heading>InStatus App</Heading>
					<Text color={'gray.500'} fontSize={'lg'}>
						Receive critical notifications from InStatus within
						Rocket.Chat.
					</Text>
					<Stack
						spacing={4}
						divider={
							<StackDivider
								borderColor={useColorModeValue(
									'gray.100',
									'gray.700',
								)}
							/>
						}
					>
						<Feature
							icon={
								<Icon
									as={IoLockClosed}
									color={'yellow.500'}
									w={5}
									h={5}
								/>
							}
							iconBg={useColorModeValue(
								'yellow.100',
								'yellow.900',
							)}
							text={
								'Receive real-time alerts from InStatus Webhooks'
							}
						/>
						<Feature
							icon={
								<Icon
									as={AiFillGithub}
									color={'black.500'}
									w={5}
									h={5}
								/>
							}
							iconBg={useColorModeValue('grey.100', 'grey.900')}
							text={'Open source and free forever.'}
						/>
						<Feature
							icon={
								<Icon
									as={GiBullseye}
									color={'purple.500'}
									w={5}
									h={5}
								/>
							}
							iconBg={useColorModeValue(
								'purple.100',
								'purple.900',
							)}
							text={'Stay up to date with incidents and outages'}
						/>
					</Stack>
				</Stack>
				<Flex>
					<></>
				</Flex>
			</SimpleGrid>
			<Button
				colorScheme="blue"
				variant="solid"
				my={10}
				onClick={() => {
					redirectToExternalLink(CONSTANTS.INSTATUS_APP_GITHUB_URL);
				}}
			>
				Click here for more info
			</Button>
		</Container>
	);
}
