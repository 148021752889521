import {
	Stack,
	Button,
	useColorModeValue,
	Heading,
	Container,
	Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export const NewsletterSubscriptionWithCTA = () => {
	const navigate = useNavigate();

	return (
		<Container
			maxW={'lg'}
			bg={useColorModeValue('white', 'whiteAlpha.100')}
			boxShadow={'xl'}
			rounded={'lg'}
			p={6}
			marginTop={'10%'}
			marginBottom={'10%'}
			borderWidth={1}
		>
			<Heading
				as={'h2'}
				fontSize={{ base: 'xl', sm: '2xl' }}
				textAlign={'center'}
				mb={5}
			>
				Subscribe to our Newsletter
			</Heading>
			<Stack
				direction={'column'}
				as={'form'}
				spacing={'12px'}
				align={'center'}
				alignSelf={'center'}
			>
				<Button
					colorScheme="blue"
					size="md"
					onClick={() => navigate('/newsletter')}
				>
					Click here to Subscribe!
				</Button>
			</Stack>
			<Text mt={2} textAlign={'center'} color={'gray.500'}>
				You won't receive any spam! ✌️
			</Text>
		</Container>
	);
};
