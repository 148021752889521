import { Box, Heading, Container, Text, Button, Stack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { redirectToExternalLink } from '../../../components/Links';
import { CONSTANTS } from '../../../constants/generalConstants';
import Testimonial from './Testinomial';

export default function ReminderAppProduct() {
	useEffect(() => {
		document.title = 'Reminder App - AppsForChat';
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<>
				<Container maxW={'3xl'}>
					<Stack
						as={Box}
						textAlign={'center'}
						spacing={{ base: 8, md: 14 }}
						py={{ base: 20, md: 36 }}
					>
						<Heading
							fontWeight={600}
							fontSize={{ base: '2xl', sm: '4xl', md: '5xl' }}
							lineHeight={'110%'}
						>
							Use ChatGPT in <br />
							<Text as={'span'} color={'red.400'}>
								Rocket.Chat
							</Text>
						</Heading>
						<Text
							color={'gray.500'}
							fontSize={{
								base: 'lg',
								md: 'xl',
							}}
						>
							Elevate your messaging experience with ChatGPT
							inside Rocket.Chat. From writing replies to
							summarizing conversations, ChatGPT will help you get
							more done in less time.
						</Text>
						<Stack
							direction={'column'}
							spacing={3}
							align={'center'}
							alignSelf={'center'}
							position={'relative'}
						>
							<Button
								colorScheme={'green'}
								bg={'green.400'}
								rounded={'full'}
								px={6}
								_hover={{
									bg: 'green.500',
								}}
								onClick={() => {
									redirectToExternalLink(
										CONSTANTS.CHATGPT_APP_GITHUB_URL,
									);
								}}
							>
								Get Started for Free
							</Button>
						</Stack>
					</Stack>
				</Container>
				<Testimonial />
			</>
		</>
	);
}
